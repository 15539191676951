import { template as template_37a60bc632504780a7261b73eaccc0d7 } from "@ember/template-compiler";
const WelcomeHeader = template_37a60bc632504780a7261b73eaccc0d7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
